import { FC } from "react";
import { gql } from "graphql-request";
import Image from "next/image";
import { TourTicket_TourSnapshotFragment } from "@graphql/types";
import { Loading, Logo, Spacer, Stack, Text } from "@newt/ui";
import { ArrowIcon } from "./ArrowIcon";
import styles from "./TourTicket.module.css";

const TOUR_TICKET_FRAGMENT = gql`
  fragment TourTicket_TourSnapshot on TourSnapshot {
    mainImage {
      url(size: SM)
    }
    originAirport {
      code
      name
    }
    destinationAirport {
      code
      destination {
        name
      }
    }
    title
  }
`;

interface TourTicketProps {
  tour?: TourTicket_TourSnapshotFragment | null;
}

export const TourTicket: FC<TourTicketProps> = (props) => {
  const { tour } = props;

  if (!tour) {
    return <Loading />;
  }

  return (
    <div className={styles.scale}>
      <div className={styles.root}>
        <Image
          src={tour.mainImage.url}
          alt="シェア画像"
          width={240}
          height={160}
          className={styles.image}
        />
        <div className={styles.content}>
          <Stack as="div" align="center" justify="between" spacing={"0"}>
            <div className={styles.airport}>
              <Text weight="bold" size="4xl">
                {tour.originAirport?.code}
              </Text>

              <Text size="xs" color="gray" truncate={true}>
                {tour.originAirport?.name}
              </Text>
            </div>
            <i>
              <ArrowIcon />
            </i>
            <div className={styles.airport}>
              <Text weight="bold" size="4xl" align="right">
                {tour.destinationAirport?.code}
              </Text>
              <Text size="xs" color="gray" align="right" truncate={true}>
                {tour.destinationAirport?.destination?.name}
              </Text>
            </div>
          </Stack>
          <Spacer y={"3x"} />
          <div className={styles.title}>{tour.title}</div>
        </div>
        <div className={styles.logo}>
          <Logo variant="horizontal" color="primary" height={"16px"} />
        </div>
      </div>
    </div>
  );
};
