import { openChatMessage } from "@newt/core";
import { ROUTES } from "@routes";
import { useAuth } from "@utils/auth";
import { useRouter } from "next/router";
import { FC, createContext, ReactNode, useEffect } from "react";
import { useZendesk, ZendeskProvider } from "react-use-zendesk";
import { CHAT_FIELD_IDS, ZENDESK_KEY } from "../constants";
import { useMutation, useQuery } from "@tanstack/react-query";
import { graphql } from "@utils/graphql";
import Script from "next/script";
import { useIsWebview } from "@utils/webview";
import { gql } from "graphql-request";
import { MarketingService } from "@utils/marketing";
import { ClickModuleId } from "@utils/marketing/events/clickModule";
import { findClickModuleNameById } from "@utils/marketing/formatter/findClickModuleName";

export const USE_CREATE_ZENDESK_TOKEN_MUTATION = gql`
  mutation UseCreateZendeskToken {
    createZendeskToken {
      token
    }
  }
`;

export const USE_FETCH_RESERVATIONS = gql`
  query UseFetchReservations {
    me {
      activeBookings(first: 10) {
        edges {
          node {
            code
          }
        }
      }
    }
  }
`;

export type SupportChatOpenFromValue = ClickModuleId | "helpPage";

export interface SupportChatContextState {
  onOpen: (openFrom: SupportChatOpenFromValue, tourCode?: string) => void;
  unreadMessages: string | undefined;
}

interface SupportChatContextProviderProps {
  children: ReactNode;
}

export const SupportChatContext = createContext<SupportChatContextState>({
  onOpen: () => {},
  unreadMessages: undefined,
});

const SupportChat: FC<SupportChatContextProviderProps> = ({ children }) => {
  const router = useRouter();
  const isWebview = useIsWebview();
  const isChatOpen = router.query.chat === "open";
  const openFrom = router.query.openFrom;
  const { isLoading, user } = useAuth();
  const { open, close, unreadMessages, loginUser, setConversationFields } =
    useZendesk();
  const { data: zendeskToken, mutateAsync: createZendeskTokenMutation } =
    useMutation({
      mutationFn: async () => {
        const data = await graphql.UseCreateZendeskToken();

        return data.createZendeskToken.token;
      },
    });
  const { data: reservationCode } = useQuery({
    queryKey: ["fetchReservations"],
    queryFn: async () => {
      const data = await graphql.UseFetchReservations();
      const codes = data.me?.activeBookings.edges.map((edge) => edge.node.code);

      return codes?.join(",");
    },
    enabled: !!user,
  });

  const handleOpen = (
    openFrom: SupportChatOpenFromValue,
    tourCode?: string
  ) => {
    if (openFrom === "helpPage") {
      MarketingService.logEvent.pageView({
        user_id: user?.id ? user.id.toString() : undefined,
        user_status: user ? "member" : "guest",
        previous_page: "https://newt.zendesk.com/hc/ja",
      });
    } else if (openFrom != undefined) {
      MarketingService.logEvent.clickModule({
        module_id: openFrom,
        module_name: findClickModuleNameById(openFrom),
        is_newt_app: isWebview,
      });
    }

    if (isWebview) {
      openChatMessage();

      return;
    }

    if (!isLoading && !user) {
      const url = new URL(router.asPath, window.location.origin);
      const params = url.searchParams;

      if (!params.has("chat")) {
        params.append("chat", "open");
      }

      if (!openFrom) {
        params.append("openFrom", openFrom);
      }

      const modifiedPath = `${url.pathname}?${params.toString()}`;
      router.push(ROUTES.signin.index({ redirectTo: modifiedPath }));

      return;
    }

    setConversationFields([
      {
        id: CHAT_FIELD_IDS.NAME,
        value: user?.displayName || "",
      },
      {
        id: CHAT_FIELD_IDS.PHONE,
        value: user?.phone || "",
      },
      {
        id: CHAT_FIELD_IDS.TOUR_CODE,
        value: tourCode || "",
      },
      {
        id: CHAT_FIELD_IDS.RESERVATION_ID,
        value: reservationCode || "",
      },
    ]);

    open();
  };

  const formatUnreadMessagesForDisplay = () => {
    if (!unreadMessages) return undefined;

    return unreadMessages > 99 ? "99+" : unreadMessages.toString();
  };

  useEffect(() => {
    if (isLoading || !user) return;

    if (!zendeskToken) {
      createZendeskTokenMutation().then((token) => {
        loginUser(token);
      });
    }
  }, [isLoading, user, zendeskToken]);

  useEffect(() => {
    if (isLoading || !router.isReady) return;

    if (isChatOpen) {
      const url = new URL(router.asPath, window.location.origin);
      const params = url.searchParams;
      params.delete("chat");
      params.delete("openFrom");

      const newSearch = params.toString();
      url.search = newSearch ? `?${newSearch}` : "";

      router.replace(url.pathname + url.search, undefined, { shallow: true });
      setTimeout(() => handleOpen(openFrom as SupportChatOpenFromValue), 2000);
    }
  }, [isLoading, router.pathname, router.isReady]);

  useEffect(() => {
    return () => {
      close();
    };
  }, []);

  const value = {
    unreadMessages: formatUnreadMessagesForDisplay(),
    onOpen: handleOpen,
  };

  return (
    <SupportChatContext.Provider value={value}>
      {children}
    </SupportChatContext.Provider>
  );
};

export const SupportChatProvider: FC<SupportChatContextProviderProps> = ({
  children,
}) => {
  return (
    <>
      {/* PCやSafariなどでチャットが表示されない問題を解消するための無理やりscript追加 */}
      <Script
        id="ze-snippet"
        src={`https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`}
      />
      <ZendeskProvider apiKey={ZENDESK_KEY}>
        <SupportChat>{children}</SupportChat>
      </ZendeskProvider>
    </>
  );
};
